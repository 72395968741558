import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p className="footer-links">
 
        <a href="mailto:developer@aiexperts.in" target="_blank">
          Need any help?
        </a>
        <span> / </span>
        <a href="https://eulerslab.com" target="_blank">
          Say Hi on Twitter
        </a>
        <span> / </span>
        <a href="https://eulerslab.com" target="_blank">
          Follow us on facebook
        </a>
      </p>
      <p>
        &copy; {currentYear} <strong>EulersLab</strong> - Designed & Developed by EulersLab
      </p>
    </footer>
  );
};

export default Footer;
