import React, { createContext, useContext, useState,useEffect } from 'react';
import axios from 'axios';
import { useFormikContext } from 'formik'; // Import useFormikContext
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import {
  CheckoutStateContext,
  CheckoutDispatchContext,
  CHECKOUT_STEPS,
  setCheckoutStep,
  saveShippingAddress,
} from "contexts/checkout";
import { CartStateContext } from "contexts/cart";
import { AuthStateContext, AuthDispatchContext, signOut } from "contexts/auth";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import _get from "lodash.get";
import Input from "components/core/form-controls/Input";
import { phoneRegExp } from "constants/common";


const AddressSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(phoneRegExp, "Phone Number is not a valid 10 digit number")
    .min(10, "Phone Number is too short")
    .max(12, "Phone Number is too long"),
  addressLine: Yup.string().required("Door No. & Street is required!"),
  city: Yup.string().required("City is required!"),
  state: Yup.string().required("State is required!"),
  code: Yup.string().required("ZIP/Postal code is required!"),
  country: Yup.string().required("Country is required!")
});

const LoginStep = () => {
  const history = useHistory();
  const { user, isLoggedIn } = useContext(AuthStateContext);
  const authDispatch = useContext(AuthDispatchContext);
  const checkoutDispatch = useContext(CheckoutDispatchContext);
  const handleContinueShopping = () => {
    history.push("/");
  };
  const handleLoginAsDiffUser = () => {
    signOut(authDispatch);
    history.push("/auth");
  };
  const handleGotoLogin = () => {
    history.push("/auth");
  };
  const handleProceed = () => {
    setCheckoutStep(checkoutDispatch, CHECKOUT_STEPS.SHIPPING);
  };
  return (
    <div className="detail-container">
      <h2>Sign In now!</h2>
      <div className="auth-message">
        {isLoggedIn ? (
          <>
            <p>
              Logged in as <span>{user.username}</span>
            </p>
            <button onClick={() => handleLoginAsDiffUser()}>
              Login as Different User
            </button>
          </>
        ) : (
          <>
            <p>Please login to continue.</p>
            <button onClick={() => handleGotoLogin()}>Login</button>
          </>
        )}
      </div>
      <div className="actions">
        <button className="outline" onClick={() => handleContinueShopping()}>
          <i className="rsc-icon-arrow_back" /> Continue Shopping
        </button>
        <button disabled={!isLoggedIn} onClick={() => handleProceed()}>
          Proceed
          <i className="rsc-icon-arrow_forward" />
        </button>
      </div>
    </div>
  );
};

// New component to fetch and set user address
const FetchAndSetUserAddress = ({ user, setNotification }) => {
  const { setValues } = useFormikContext();

  useEffect(() => {
    const fetchUserAddress = async () => {
      if (!user || !user.username) return;
      
      try {
        const response = await fetch('https://api.eulerslab.com/bot/client_id_101/get_user_address_for_store', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ mobile_no: user.username }),
        });
        
        if (!response.ok) throw new Error('Failed to fetch address');
        
        const data = await response.json();
        if (data.flag === true && data.status === 'success') {
          setValues(data.message); // Assuming data.message is an object with address fields
        } else {
          setValues(prevValues => ({
            ...prevValues,
            addressLine: '',
            city: '',
            state: '',
            code: '',
            country: ''
          }));
          setNotification("Please enter your address.");
        }
      } catch (error) {
        console.error('Error fetching user address:', error);
        setNotification("Error fetching address. Please enter your address manually.");
      }
    };

    fetchUserAddress();
  }, [user, setValues, setNotification]);

  return null; // This component does not render anything
};

//Component to save address
// Example API call function to save the address
const saveAddressToServer = async (addressData) => {
  try {
    const response = await fetch('https://api.eulerslab.com/bot/client_id_101/save_user_address_for_store', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary headers, like authorization tokens
      },
      body: JSON.stringify(addressData),
    });
    if (!response.ok) {
      throw new Error('Failed to save address');
    }
    const data = await response.json();
    return data; // Return the response data
  } catch (error) {
    console.error('Error saving address:', error);
    throw error; // Rethrow to handle it in the calling context
  }
};


// Assuming all imports and other component definitions remain unchanged

const AddressStep = () => {
  const history = useHistory();
  const { user } = useContext(AuthStateContext);
  const checkoutDispatch = useContext(CheckoutDispatchContext);
  const [notification, setNotification] = useState('');

  const handleContinueShopping = () => {
    history.push("/");
  };
  
   const handleBackToLogin = () => {
    setCheckoutStep(checkoutDispatch, CHECKOUT_STEPS.AUTH);
  };
  const handleSaveAddress = async (addressData) => {
    try {
      // Assuming saveAddressToServer is an async function making the API call
      const savedAddress = await saveAddressToServer(addressData);
      console.log('Address saved successfully:', savedAddress);
      setCheckoutStep(checkoutDispatch, CHECKOUT_STEPS.PAYMENT);
      saveShippingAddress(checkoutDispatch, addressData);
    } catch (error) {
      console.error("Failed to save address:", error);
      setNotification("Failed to save address. Please try again.");
    }
  };
  
  return (
    <div className="detail-container">
      <h2>Shipping Address</h2>
      {notification && <div className="notification">{notification}</div>}
      <Formik
        initialValues={{
          fullName: '',
          phoneNumber: '',
          addressLine: '',
          city: '',
          state: '',
          code: '',
          country: ''
        }}
        validationSchema={AddressSchema}
        enableReinitialize={true} // Add this prop
        onSubmit={async (values, { setSubmitting }) => {
          try {
            // Call the API to save the address
            const savedAddress = await saveAddressToServer(values);
            console.log('Address saved successfully:', savedAddress);

            // Proceed with the next step or handle success (e.g., show confirmation)
            //handleSaveAddress(checkoutDispatch, values);
            setCheckoutStep(checkoutDispatch, CHECKOUT_STEPS.PAYMENT);
            saveShippingAddress(checkoutDispatch,values) ;
            
          } catch (error) {
            // Handle errors (e.g., show error message)
            setNotification("Failed to save address. Please try again.");
          } finally {
            setSubmitting(false); // Ensure to set submitting to false in all cases
          }
        }}
      >
        {/* Render props method provides access to Formik's context */}
        {formikProps => (
          <Form>
          <FetchAndSetUserAddress user={user} setNotification={setNotification} />
          <div className="field-group">
            <Field
              name="fullName"
              type="text"
              placeholder="Full Name"
              component={Input}
            />
            <Field
              name="phoneNumber"
              type="text"
              placeholder="Contact no"
              component={Input}
            />
          </div>
          <Field
            name="addressLine"
            type="text"
            placeholder="Door No. & Street"
            component={Input}
          />
          <div className="field-group">
            <Field
              name="city"
              type="text"
              placeholder="City"
              component={Input}
            />
            <Field
              name="state"
              type="text"
              placeholder="State"
              component={Input}
            />
          </div>
          <div className="field-group">
            <Field
              name="code"
              type="text"
              placeholder="ZIP/Postal Code"
              component={Input}
            />
            <Field
              name="country"
              type="text"
              placeholder="Country"
              component={Input}
            />
          </div>
          <div className="actions">
          <div className="top-buttons">
            <button
              type="button"
              className="outline"
              onClick={() => handleBackToLogin()}
            >
              <i className="rsc-icon-arrow_back" /> Login in as Different User
            </button>
            <button type="submit">
              Save Address
              <i className="rsc-icon-arrow_forward" />
            </button>
          </div>
          <div className="bottom-buttons">
          <button 
               type="button"
               className="outline" 
               onClick={() => handleContinueShopping()}>
              <i className="rsc-icon-arrow_back" /> Continue Shopping
            </button>
          </div>
        </div>

        </Form>
        )}
      </Formik>
    </div>
  );
};


const PaymentStep = () => {
  const history = useHistory();
  const { shippingAddress } = useContext(CheckoutStateContext);
  const checkoutDispatch = useContext(CheckoutDispatchContext);
  const { user, isLoggedIn } = useContext(AuthStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const { items=[] } = useContext(CartStateContext);
  const [order_id, setOrderID] = useState(null);
  const [checkStatusAttempts, setCheckStatusAttempts] = useState(0);

  useEffect(() => {
    if (order_id && checkStatusAttempts < 5) {
      const timeoutId = setTimeout(checkOrderStatus, 20000);
      return () => clearTimeout(timeoutId); // Clean up the timeout on effect cleanup
    }
  }, [order_id, checkStatusAttempts]); // Depend on order_id and checkStatusAttempts

  const handleContinueShopping = () => {
    history.push("/");
  };

  const handleBackToAddress = () => {
    setCheckoutStep(checkoutDispatch, CHECKOUT_STEPS.SHIPPING);
  };

  const checkOrderStatus = async () => {
    if (!order_id || checkStatusAttempts >= 5) {
      console.log("Stopping checks, attempts: ", checkStatusAttempts);
      return;
    }

    setCheckStatusAttempts(prevAttempts => prevAttempts + 1);
  
    try {
      const result = await axios.post('https://api.eulerslab.com/bot/client_id_101/check_payment_status_redis', { order_id });

      if (result.data.status === "success") {
        setPaymentStatus('Payment successful');
        setIsLoading(false);
      } else {
        console.log("Payment check failed, attempt number: ", checkStatusAttempts);
      }
    } catch (error) {
      console.error('Error checking order status:', error);
    }
  };

  const handleMakePayment = async () => {
    setIsLoading(true);

    try {
        const totalPrice = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const paymentLinkResponse = await axios.post('https://api.eulerslab.com/bot/client_id_101/generate_payment_link_store', {
            mobile_no: user.username,
            name: shippingAddress.fullName,
            code: items.map(item => item.id),
            quantity: items.map(item => item.quantity),
            total_price: totalPrice * 100
        });

        if (paymentLinkResponse.data.status === "success" && paymentLinkResponse.data.message) {
            const paymentPopup = window.open(paymentLinkResponse.data.message, '_blank', 'width=600,height=600');
            if (paymentPopup) {
                setOrderID(paymentLinkResponse.data.order_id);
            } else {
                alert("Popup blocked! Please allow popups for this site and try again.");
            }
        } else {
            console.error("Failed to generate payment link");
        }
    } catch (error) {
        console.error('Error initiating payment:', error);
    } finally {
        setIsLoading(false);
    }
};


  return (
    <div className="detail-container">
      <h2>Payment</h2>
      {isLoading && <div className="loader">Processing your payment...</div>}
      {!isLoading && paymentStatus && <div className="payment-status">{paymentStatus}</div>}
      <div className="actions">
        <div className="top-buttons">
          <button type="button" className="outline" onClick={handleBackToAddress}>
            <i className="rsc-icon-arrow_back" /> Back to Shipping Details
          </button>
          <button disabled={!shippingAddress} onClick={handleMakePayment}>
            Make Payment
            <i className="rsc-icon-arrow_forward" />
          </button>
        </div>
        <div className="bottom-buttons">
          <button type="button" className="outline" onClick={handleContinueShopping}>
            <i className="rsc-icon-arrow_back" /> Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

  

const Checkout = () => {
  const { items = [] } = useContext(CartStateContext);
  const { isLoggedIn } = useContext(AuthStateContext);
  const { step, shippingAddress } = useContext(CheckoutStateContext);
  const checkoutDispatch = useContext(CheckoutDispatchContext);
  const totalItems = items.length;

  // Assuming a fixed tax rate of 10% and a fixed shipping fee
const TAX_RATE = 0;
const SHIPPING_FEE = 0;

const subtotal = items.reduce((total, product) => total + product.price * product.quantity, 0);
const tax = subtotal * TAX_RATE;
const total = subtotal + tax + SHIPPING_FEE;

  const handleClickTimeline = (nextStep) => {
    setCheckoutStep(checkoutDispatch, nextStep);
  };

  return (
    <div className="checkout-page">
      <div className="container">
        <div className="order-details">
          <ul className="timeline">
            <li
              className={classNames({
                done: isLoggedIn,
                active: step === CHECKOUT_STEPS.AUTH
              })}
              onClick={() => handleClickTimeline(CHECKOUT_STEPS.AUTH)}
            >
              <h2>Sign In</h2>
              <i className="rsc-icon-check_circle" />
            </li>
            <li
              className={classNames({
                done: shippingAddress !== null,
                active: step === CHECKOUT_STEPS.SHIPPING
              })}
              onClick={() => handleClickTimeline(CHECKOUT_STEPS.SHIPPING)}
            >
              <h2>Shipping</h2>
              <i className="rsc-icon-check_circle" />
            </li>
            <li
              className={classNames({
                done: false,
                active: step === CHECKOUT_STEPS.PAYMENT
              })}
              onClick={() => handleClickTimeline(CHECKOUT_STEPS.PAYMENT)}
            >
              <h2>Payment</h2>
              <i className="rsc-icon-check_circle" />
            </li>
          </ul>
          {step === CHECKOUT_STEPS.AUTH && <LoginStep />}
          {step === CHECKOUT_STEPS.SHIPPING && <AddressStep />}
          {step === CHECKOUT_STEPS.PAYMENT && <PaymentStep />}
        </div>
      <div className="order-summary">
        <h2>
          Summary
          <span>{` (${totalItems}) Items`}</span>
        </h2>
        <ul className="cart-items">
          {items.map((product) => (
            <li className="cart-item" key={product.name}>
              <img className="product-image" src={product.image} alt={product.name} />
              <div className="product-info">
                <p className="product-name">{product.name}</p>
                <p className="product-price">{`${product.price} per unit`}</p>
              </div>
              <div className="product-total">
                <p className="quantity">{`${product.quantity} ${product.quantity > 1 ? "Nos." : "No."}`}</p>
                <p className="amount">{product.quantity * product.price}</p>
              </div>
            </li>
          ))}
        </ul>

        <ul className="total-breakup">
          <li>
            <p>Subtotal</p>
            <p>{subtotal.toFixed(2)}</p>
          </li>
          <li>
            <p>Tax</p>
            <p>{tax.toFixed(2)}</p>
          </li>
          <li>
            <p>Shipping</p>
            <p>{SHIPPING_FEE.toFixed(2)}</p>
          </li>
          <li>
            <h2>Total</h2>
            <h2>{total.toFixed(2)}</h2>
          </li>
        </ul>
      </div>

      </div>
    </div>
  );
};

export default Checkout;
