import React, {useState, useContext } from "react";
import { Formik, Form, Field,useFormikContext } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import _get from "lodash.get";
import { AuthDispatchContext, signIn } from "contexts/auth";
import Input from "components/core/form-controls/Input";
import axios from 'axios';

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("OTP is required!"),
  username: Yup.string()
    .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits")
    .required("Mobile Number activated on WhatsApp is required!")
});



const AuthPage = () => {
  const authDispatch = useContext(AuthDispatchContext);
  const history = useHistory();
  const location = useLocation();
  const fromUrl = _get(location, "state.from.pathname");
  
  const signInSuccess = async (userData) => {
    try {
      const response = await axios.post('https://api.eulerslab.com/bot/client_id_101/verify_otp',{
        mobile_no: userData.username,
        OTP: userData.password, // Assuming the OTP is entered in the password field
      });
      console.log(response.data)
      if (response.data.status === "success") {
        signIn(authDispatch, { username: userData.username });
        if (fromUrl) {
          history.push(fromUrl);
        } else {
          history.push("/");
        }
      } else {
        console.error("OTP verification failed");
        // Handle OTP verification failure (e.g., show an error message)
      }
    } catch (err) {
      console.error(err);
      // Handle error (e.g., show an error message)
    }
  };
  

  return (
    <Formik
      initialValues={{
        username: "",
        password: ""
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          // Append "91" to the username before proceeding
          const modifiedValues = {
            ...values,
            username: `91${values.username}`,
          };
          await signInSuccess(modifiedValues); // Pass modified values for sign in
          resetForm();
        } catch (err) {
          console.error(err);
        }
      }}
      
    >
      {() => (
        <AuthForm />
      )}
    </Formik>
  );
};

export default AuthPage;

// Inner component AuthForm
const AuthForm = () => {
  const { values, handleChange, setFieldTouched } = useFormikContext();
  const [otpSent, setOtpSent] = useState(false); // State to track if the OTP was sent
  const [notification, setNotification] = useState(""); // State for displaying messages to the user


  const handleUsernameChange = async (e) => {
    handleChange(e);
    setFieldTouched('username', true, false);
    if (e.target.value.length === 10) {
      setNotification("Sending OTP to your mobile number..."); // Inform the user that OTP is being sent
      await otpsend(e, e.target.value);
    }
  };


  const goToForgotPassword = async (e,mobileNumber) => {
    e.preventDefault();
    if (mobileNumber.length === 10) {
      try {
        const otpResponse = await sendOTP(`91${mobileNumber}`); 
        console.log('OTP sent successfully:', otpResponse);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const goToRegister = (e) => {
    e.preventDefault();
    // Your logic for register
  };

  const otpsend = async (e, mobileNumber) => {
    e.preventDefault();
    if (mobileNumber.length === 10 && !otpSent) {
      try {
        await sendOTP(`91${mobileNumber}`);
        setOtpSent(true); // Mark OTP as sent
        setNotification("OTP has been sent to your mobile number. Please enter it below."); // Inform the user
      } catch (error) {
        console.error(error);
        setNotification("Failed to send OTP. Please try again."); // Inform the user in case of an error
      }
    }
  };

  const sendOTP = async (mobileNumber) => {
    try {
      const response = await axios.post('https://api.eulerslab.com/bot/client_id_101/send_otp_message', {
        mobile_no: mobileNumber,
      });
      return response.data;
    } catch (error) {
      console.error('Error sending OTP', error);
    }
  };

  return (
    <Form>
      <Field
        name="username"
        type="text"
        placeholder="Enter Mobile Number"
        component={Input}
        onChange={handleUsernameChange}
      />
      {notification && <p>{notification}</p>} {/* Display the notification message */}
      <Field
        name="password"
        type="password"
        placeholder="Enter OTP"
        component={Input}
      />
      <p>
        <a href="/#" onClick={goToForgotPassword}>
          Resend OTP
        </a>
      </p>
      <button className="auth-button block" type="submit">
        Login
      </button>
      <p>
      Mobile no activated on whatsapp  is required.
      </p>
    </Form>
  );
};
